.page-navigation {
  font-size: 20px;
  display: block;
  width: 100%;
  overflow: hidden;
}
.page-navigation a {
  display: block;
  width: 50%;
  float: left;
  margin: 1em 0;
}
.page-navigation .next {
  text-align: right;
}
