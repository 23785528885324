/**
Stuff for the "Series" pagination feature
*/

.page-series-navigation {
    display: block;
    margin-left: 10px;
    margin-right: 10px;
    border: 1px solid burlywood;
    background: snow;
    border-radius: 5px;
    padding: 20px;

    ol {
        margin-top: 10px;

        li {
            padding-bottom: 5px;
        }
    }

    .series-title {
        font-style: italic;
        font-size: 2em;
        display: block;
        text-align: center;
    }

    .series-description {
        display: block;
        text-align: justify;
    }

    .series-current-post {
        font-weight: bold;
    }
}

