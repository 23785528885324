/*
    Include files for interactive gadgets
*/

.gadget {
    border: 1px dashed grey;
    padding: 10px;
    margin-bottom: 30px;
    margin-top: 30px;

    box-sizing: border-box;

    .gadget-holder {
        display: flex;

        .gadget-column {
            flex: 50%;
            padding: 5px;
        }

        td:hover {
            background-color: aqua;
            cursor: pointer;
        }

        td.paged {
            background-color: lightpink;
        }

        td.empty {
            color: lightslategray;
        }
    }
}